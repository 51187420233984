import { Br, PageHeader } from "components";
import React from "react";

const CareersPageHeader = () => {
  return (
    <PageHeader
      title={
        <>
          We Work, We Play, <Br on="desktop" />
          We Make it Happen
        </>
      }
      subheading={
        <>
          The future of financial and banking services in Africa is digital,{" "}
          <Br on="desktop" /> and we want you to join us to build it.
        </>
      }
      center
      button={{ text: "Build with Parkway", to: "/careers#opening" }}
      theme="light"
    />
  );
};

export { CareersPageHeader };
