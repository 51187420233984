import { Container, Text, Button } from "components";
import React from "react";
import ctl from "@netlify/classnames-template-literals";

const Roles = () => {
  return (
    <section id="opening">
      <Container>
        <h5 className={openingPrompt}>WANT TO JOIN THE TEAM?</h5>
        <Text value={<>Our available roles</>} variant="h3" />

        <div className={jobsListContainer}>
          {jobList.map((job, index) => {
            return (
              <div key={index} className={jobCard}>
                <h4 className={jobTitle}>{job.title}</h4>
                <p className={jobType}>{job.type}</p>
                <Button text="Apply" size="small" to={job.applicationLink} />
              </div>
            );
          })}
        </div>
      </Container>
    </section>
  );
};

const openingPrompt = ctl(`
  font-bold
  text-[14px]
  mb-[12px]
`);

const jobsListContainer = ctl(`
  text-left
  py-8
  mx-auto
`);

const jobCard = ctl(`
  mt-8
  p-4
  flex
  lg:flex-row
  flex-col
  justify-between
  lg:items-center
  gap-y-4
  border-b-2
`);

const jobTitle = ctl(`
  font-bold
`);

const jobType = ctl(`
  font-bold
`);

const jobList = [
  {
    title: "Senior Software Engineer",
    type: "Hybrid",
    applicationLink: "#",
  },
  {
    title: "Quality Assurance Engineer",
    type: "Hybrid",
    applicationLink: "#",
  },
];

export { Roles };
