import { Br, Container, Text } from "components";
import React from "react";
import ctl from "@netlify/classnames-template-literals";
import HybridWorkIcon from "svgs/hybrid-work.svg";
import PaidLeaveAllowanceIcon from "svgs/paid-allowance.svg";
import GadgetAllowanceIcon from "svgs/gadget-allowance.svg";

const Perks = () => {
  return (
    <section className={perksWrapperStyle}>
      <Container>
        <Text
          value={
            <>
              Building the foundation for the
              <Br on="desktop" />
              next big fintechs comes with its perks.
            </>
          }
          variant="h2"
        />

        <div className={perksListStyle}>
          {perksList.map((perk, index) => {
            return (
              <div key={`perks_${index}`}>
                {perk.icon}
                <Text variant="h4" value={perk.title} />
              </div>
            );
          })}
        </div>
      </Container>
    </section>
  );
};

const perksWrapperStyle = ctl(`
md:py-[90px]
py-[61px]
text-center
`);

const perksListStyle = ctl(`
grid
md:grid-cols-3
gap-[64px]
md:mt-[113px]
mt-[44px]
`);

const perkIconStyle = ctl(`
mx-auto
h-[56px]
mb-4
`);
const perksList = [
  {
    icon: <HybridWorkIcon className={perkIconStyle} />,
    title: "Hybrid work system",
  },
  {
    icon: <PaidLeaveAllowanceIcon className={perkIconStyle} />,
    title: "Paid leave allowance",
  },
  {
    icon: <GadgetAllowanceIcon className={perkIconStyle} />,
    title: "Gadget allowance",
  },
];

export { Perks };
