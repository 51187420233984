import React from "react";
import ctl from "@netlify/classnames-template-literals";
import { StaticImage } from "gatsby-plugin-image";

import { Br, Container, Text } from "components";

const BuildExperience = () => {
  return (
    <section className={buildExperienceWrapperStyle}>
      <Container>
        <Text
          value="BuildExperience at Parkway"
          variant="h2"
          className="mb-3"
        />
        <Text
          value={
            <>
              Hear from some of the most amazing talents working round the clock
              to support <Br on="desktop" />
              financial technology companies across the continent
            </>
          }
          variant="p18"
        />

        <div className={testimonialStyle}>
          {testimonials.map((testimony, index) => {
            return (
              <div className={testimonyStyle} key={`testimony_${index}`}>
                {testimony.image}
                <Text
                  variant="h4"
                  value={testimony.name}
                  className="mb-1 mt-4"
                />
                <Text variant="p16" value={testimony.text} />
              </div>
            );
          })}
        </div>
      </Container>
    </section>
  );
};

const buildExperienceWrapperStyle = ctl(`
md:py-[90px]
py-[61px]
bg-secondary
text-secondary-100
`);

const testimonialStyle = ctl(`
grid
lg:grid-cols-3
md:grid-cols-2
gap-8
md:mt-[54px]
mt-[44px]
`);

const testimonyStyle = ctl(`
bg-secondary-1000
rounded-xl
px-[28px]
pt-[42px]
pb-[31px]
`);
const testimonyImageStyle = ctl(`
max-w-[71px]
`);

const testimonials = [
  {
    image: (
      <StaticImage
        src="../../../assets/images/jpegs/careers/damilola.png"
        alt="Damilola Asuelimen"
        className={testimonyImageStyle}
      />
    ),
    name: "Damilola Asuelimen",
    text: "Working with Parkway has been a very great start for my career, it has added a lot of experience to me in a short period of time. I have done a minimum of two courses because we are encouraged to keep growing and learning. ",
  },
  {
    image: (
      <StaticImage
        src="../../../assets/images/jpegs/careers/wisdom.png"
        alt="Wisdom Johnson"
        className={testimonyImageStyle}
      />
    ),
    name: "Wisdom Johnson",
    text: "Joining Parkway has been welcoming and amazing. In Parkway, we like to get the work done no matter how insurmountable the task may be. everybody is always about getting the job done, I find this totally amazing. You will find your place here at Parkway.",
  },
  {
    image: (
      <StaticImage
        src="../../../assets/images/jpegs/careers/oladayo.png"
        alt="Oladayo Olukowi"
        className={testimonyImageStyle}
      />
    ),
    name: "Oladayo Olukowi",
    text: "My experience working at Parkway has been fun and informative. I have been able to meet a lot of intelligent and creative people who find a creative way to get the job done. The work culture and environment have been very good.",
  },
];

export { BuildExperience };
